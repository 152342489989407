import React from "react"
import Layout from "../components/layout"

export default (props) => {

  return (
      <Layout location={props.location}>
        <div style={{ margin: `3rem auto`, maxWidth: 600 }}>
          <h1>My name is Yazan and I like to write songs</h1>
          <p>
            I made this site to tell more of the story of all the songs I've released. This site is a work in progress.
          </p>
        </div>
      </Layout>
  )
}
